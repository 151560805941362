.aggr-col {
  background-color: bisque;
}

.report-widget .react-grid-layout {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 2rem !important;
  overflow-y: auto !important;
}

/* .report-widget .react-grid-layout > div {
  width: 100% !important;
  transform: none !important;
  position: unset !important;
  height: 1270px !important;
  /* height: 110px !important;
  position: unset !important;
  transform: none !important; 
} */
