.report-radiobtn-container {
    margin-bottom: 12px;
}
.report-radiobtn-container .ant-radio-wrapper {
    display: flex;
    align-items: center;
}   

.report-radiobtn-container .ant-radio-wrapper .ant-radio {
    top: 0 !important;
}

.report-radiobtn-container .ant-radio-group {
    display: flex;
}