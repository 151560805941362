.custom-table .ant-table-thead th{
    padding: 15px 10px!important;
}
.custom-table .ant-table-tbody td{
    padding: 15px 10px!important;
}
.form-label{
    font-size: 14px;
    color: #4D4D4D;
}
.form-data{
    font-size: 16px;
    color: #333333;
}
.mb-24{
    margin-bottom: 24px;
}
.mb-16{
    margin-bottom: 16px;
}
.mr-16{
    margin-right: 16px;
}
.timeline-clock-icon{
    color:#666666;
    font-size: 18px;
}
.custom-table .ant-table-content{
    min-height: fit-content;
    max-height: 80vh;
}
.no-word-break{
    white-space: nowrap;
}
.w-50{
    width: 50%;
}
.w-20{
    width: 20%;
}