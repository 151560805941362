.report-grid {
    max-height: 310px;
    overflow: auto;
}
.widget-table{
    width: 100%;
    margin-bottom: 16px;
}
.widget-table thead {
    /* position: sticky;
    top: 0; */
}
.widget-table thead th{
    background: #fafafa;
    padding: 16px;
    border-bottom: 1px solid #f0f0f0!important;
    text-align: start;
    font-weight: 600;
    position: sticky;
    text-align: left;
}
.widget-table tbody td, tfoot td{
    padding: 10px 16px;
    text-align: left;
}
.widget-table tfoot td{
    text-align: left;
}
.widget-table tfoot td:first-child{
    text-align: start;
}
/* .widget-table-container {
    height: auto;
} */

.summary-data-space {
    border-right: 1px solid #ddd;
}

.report-name {
    font-size: 24px;
    font-weight: bold;
}

.build-talals-row .build-talals-item {
    text-align: left;
    background-color: #e0e0e0;
    padding: 16px;
}

@media print {
    .widget-table {
        width: 100%;
        height: 100%;
    }

    .widget-table-container {
        height: auto;
        overflow: hidden
    }
}

