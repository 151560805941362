.tag-letter-container{
    margin-right: 10px;
    color: #fff;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
} 
.content-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.content-item{
    display: flex;
    align-items: center;
    padding: 12px 16px;
}
.content-item:hover{
    background-color: #E6F6FF;
}
.ml-16{
    margin-left: 16px;
}
.menu-overlay .ant-popover-inner-content{
    padding: 0;
}