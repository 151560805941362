.report-table {
  width: 100%;
}

.ant-table-content {
  overflow: auto;
  overflow-x: auto;
  max-height: 320px;
}

.report-grid .ant-table-thead th {
  padding: 1rem !important;
}

.report-grid .ant-table-tbody td {
  padding: 1rem !important;
}

.report-grid .ant-table-summary td {
  padding: 1rem !important;
}

.report-grid .ant-table-summary tr:nth-child(2n) {
  background-color: #fbfbfb !important;
}

.report-grid .ant-table-summary tr.tblTotal td.ant-table-cell {
  border: none !important;
}


/* .DashboardLayout__body__mainContent h5 {
  display: none;
} */

.report-grid .summary__content {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
