.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.MiddleCol {
  border-right: 5px solid #fff;
  background: rgb(241, 241, 241);
}

#content {
  width: 100%;
}
.react-grid-layout {
  background: #eee;
  margin-top: 10px;
}
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
.react-grid-item {
  box-sizing: border-box;
}
.react-grid-item:not(.react-grid-placeholder) {
  border-radius: 5px;
  padding: 20px;
  width: 200px;
  height: 150px;
  /* border: 1px solid rgb(206, 206, 206); */
}
.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item.static {
  background: #cce;
}
.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}
.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.toolbox {
  background-color: #dfd;
  width: 100%;
  height: 120px;
  overflow: scroll;
}

.hide-button {
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 0px;
  right: 5px;
}

.toolbox__title {
  font-size: 24px;
  margin-bottom: 5px;
}
.toolbox__items {
  display: block;
}
.toolbox__items__item {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 5px;
  border: 1px solid black;
  background-color: #ddd;
}
.droppable-element {
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  user-select: none;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
.Split12 div {
  column-rule-style: solid;
  column-rule-color: #f8a100;
}
.Split12 > div > div {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  border-right: 1px solid #000000;
}
.child {
  width: 95%;
}
.ant-row {
  width: 100%;
  padding-bottom: 5px !important;
}
.parent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
}
.parentgrid {
  display: grid;
  grid-template-columns: none;
}
.ant-typography {
  margin-bottom: 0em !important;
}
.prediv {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
  margin: 1em 0;
}
.parentgrid .droppable-element > div {
  display: inline-grid;
  position: absolute;
  padding-left: 11px;
  font-weight: 900;
}
.parentgrid .droppable-element > div span {
  font-weight: normal;
}
.parent .droppable-element > div {
  display: none;
}
.layout{
  font-size: 12px !important;
  height: 460px !important;
}
.PreviewLayout .layout{
  height: 100% !important;
}
.PreviewLayout .layout .react-grid-item > div{
  width: 97% !important;
}
.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ebf1f5;
}

.slider {
  margin: 50px 50px 0;
}
.hide-button {
  position: absolute;
  right: 2px;
  top: -9px;
  cursor: pointer;
}
.charts {
  display: flex;
  justify-content: space-evenly;
  margin: 0 20px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid lightgray;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.charts {
  height: 100%;
  max-height: 850px;
  background-color: white;
  margin: 1% 3%;
  display: flex;
  justify-content: space-around;
  border-radius: 4px;
}

#barChart {
  width: 42%;
  height: 850px;
  max-height: 850px;
}

#geoChart {
  height: 850px;
  width: 55%;
  max-height: 850px;
}
.makeStyles-root-15 {
  width: 100% !important;
}

.vl {
  border-left: 1px solid rgb(196, 196, 196);
  height: 100%;
  position: absolute;
  left: 100%;
  margin-left: -3px;
  top: 0;
}
.ant-modal-footer {
  border-top: 0px solid #f0f0f0 !important;
}
.step {
  font-weight: 800;
  font-size: 20px;
  border-bottom: 2px solid #f0f0f0 !important;
}
.back {
  position: relative;
}
.DTypes {
  display: flex;
  flex-grow: 1;
}
.DTypesLeft {
  display: flex;
  flex-grow: 1;
}
.DTypesLeft{
  float: left;
}
 
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 0px 2px !important;
}
.ant-table, .ant-pagination {
  font-size: 12px !important;
}
.DashboardLayout__body__mainContent .ant-table table {
  padding: 7px;
}
.DashboardLayout__body__mainContent h5{
  /* text-align: center; */
  padding-top: 15px;
}
.DashboardLayout__body__mainContent .ant-pagination{
  margin-right: 15px;
}
.visible{
  float: right;
  display: block;
}
.invisible{
  display: none;
}
.view .layout {
  height: 100vh !important;
  overflow-y: hidden !important;
}

tr.tblTotal td {

  border-width:5px;  
  border-bottom-style:double;
  border-top-style:none;
  border-right-style:none;
  border-left-style:none;
}

.draggable {
  user-select: none;
  background-color: white;
  margin: 20px;
  padding: 20px;
  box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-in-out;
}

.draggable:hover {
  transform: scale(1.01);
}

.draggable-item {
  user-select: none;
  padding-left: 5px;
  transition: all 0.2s ease-in-out;
}

.draggable-item:hover {
  padding-left: 5px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
  transform: scale(1.01);
}

.icon {
  margin-right: 12px;
  opacity: 0.3;
  cursor: grab;
}

.grabbable {
  cursor: grab;
}

.dragLine {
  border-bottom: dashed 2px rgba(245, 75, 53, 0.445) !important;
}
.check-text input {
  display: none;
}
.check-text input ~ span {
  color: #333;
  cursor: pointer;
  padding: 6px;
  border-radius: 2px;
  font-weight: 200;
}
.check-text input ~ .checked {
  display: none;
}
.check-text input:checked ~ .checked {
  display: inline-block;
}
.check-text input:checked ~ .unchecked {
  display: none;
}
.check-text input:disabled ~ span {
  color: #999;
  cursor: not-allowed;
}
.blackNwhite {
  position: absolute;
  background-color: black;
  height: 100%;
  width: 100%;
}
iframe {
  display: none !important;
}